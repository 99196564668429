'use client';

import type { ComponentPropsWithoutRef } from 'react';
import React, { useState } from 'react';
import classNames from 'classnames';
import { z } from 'zod';
import { Card } from '$src-components/atoms/Card';
import { CmsShortTextSchema } from '$cms/types';
import { extendCmsText } from '$cms/index';
import type { AppGarage } from '$page-router/api/getGaragesByLatLong';
import { ShowAllToggle } from '@/components/atoms/ShowAllToggle';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import type { CmsServerComponent } from '@/types/components';
import { GoogleReviewsSummarySection } from './GoogleReviewsSummarySection';
import { GoogleReview } from './GoogleReview';

import styles from './index.module.scss';

const GoogleReviewsSchema = z.object({
  title: CmsShortTextSchema,
  showLessText: CmsShortTextSchema,
  showMoreText: CmsShortTextSchema,
  reviewsNumberText: CmsShortTextSchema,
});

const getComponentTranslations = (data: unknown, reviewsNumber: AppGarage['reviewsNumber']) => {
  const { title, showLessText, showMoreText, reviewsNumberText } = GoogleReviewsSchema.parse(data);
  return {
    title: title.value,
    showLessText: showLessText.value,
    showMoreText: showMoreText.value,
    reviewsNumberText: extendCmsText(reviewsNumberText.value, { reviewsNumber: reviewsNumber || '' }),
  };
};

function getDisplayableReviewsByRating(
  reviews: AppGarage['reviews'],
  minimumDisplayableReviewRating: number
): AppGarage['reviews'] {
  return reviews.filter((review) => review.rating && review.rating >= minimumDisplayableReviewRating);
}

export interface GoogleReviewsProps extends ComponentPropsWithoutRef<'div'> {
  readonly ratingNumber: AppGarage['ratingNumber'];
  readonly reviewsNumber: AppGarage['reviewsNumber'];
  readonly reviews: AppGarage['reviews'];
  readonly minimumDisplayableReviewRating: number;
}

export const GoogleReviews: CmsServerComponent<GoogleReviewsProps> = function GoogleReviews({
  id,
  className,
  ratingNumber,
  reviewsNumber,
  reviews,
  minimumDisplayableReviewRating,
  data,
}): JSX.Element | null {
  const { title, showLessText, showMoreText, reviewsNumberText } = getComponentTranslations(
    data,
    reviewsNumber
  );

  const [showAll, setShowAll] = useState(false);

  const defaultNumberOfReviewsToShow = 3;

  const acceptableReviews = getDisplayableReviewsByRating(reviews, minimumDisplayableReviewRating);

  const shouldDisplayShowAllToggle = acceptableReviews.length > defaultNumberOfReviewsToShow;

  const displayedReviews = acceptableReviews;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (reviews.length === 0 || !Number.isFinite(ratingNumber)) return null;

  return (
    <Card
      className={classNames(GoogleReviews.displayName, styles.component, className)}
      elevation={2}
      cornerRadius={2}
      id={id}
    >
      <HeadingLevels semanticLevel={2} styleLevel={3} className={styles.Title}>
        {title}
      </HeadingLevels>
      <GoogleReviewsSummarySection ratingNumber={ratingNumber} translations={{ reviewsNumberText }} />
      <div
        className={classNames(
          styles.ReviewsWrapper,
          showAll || !shouldDisplayShowAllToggle ? styles.show : styles.hide
        )}
      >
        <div
          className={classNames(
            styles.ReviewsList,
            showAll || !shouldDisplayShowAllToggle ? styles.show : styles.hide
          )}
        >
          {displayedReviews.map((review) => (
            <GoogleReview
              key={review.text || review.authorName}
              rating={review.rating}
              text={review.text}
              authorName={review.authorName}
              relativeTime={review.relativeTime}
            />
          ))}
        </div>
        {shouldDisplayShowAllToggle && (
          <ShowAllToggle
            showAll={showAll}
            toggleShowAll={toggleShowAll}
            showLessText={showLessText}
            showMoreText={showMoreText}
          />
        )}
      </div>
    </Card>
  );
};

GoogleReviews.displayName = 'GoogleReviews';
GoogleReviews.schema = GoogleReviewsSchema;
