import type { AppGarageReview } from '$page-router/api/getGaragesByLatLong';
import styles from '../../index.module.scss';

interface ReviewQuoteProps {
  readonly text: AppGarageReview['text'];
  readonly authorName: AppGarageReview['authorName'];
  readonly relativeTime: AppGarageReview['relativeTime'];
}

export function ReviewQuote({ text, authorName, relativeTime }: ReviewQuoteProps): JSX.Element | null {
  return (
    <>
      <q className={styles.reviewText}>{text}</q>
      <div className={styles.reviewAuthor}>
        <span className={styles.reviewAuthorName}>{authorName}</span>{' '}
        <span className={styles.reviewRelativeTime}> {relativeTime}</span>
      </div>
    </>
  );
}
